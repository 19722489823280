import styled from 'styled-components';

const ArrowsMapBefore = {
  first: "«",
  prev: "‹",
}

const ArrowsMapAfter = {
  next: "›",
  last: "»"
}

export const Pagination = styled.div`
  display: inline-flex;
  justify-content: center;
  color: ${(props) => props.theme.palette.primary};
  font-size: 1.4rem;
  border: 1px solid ${(props) => props.theme.palette.primary};
  border-radius: 3px;
`;

export const PageItem = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
  border-left: 1px solid ${(props) => props.theme.palette.primary};
  font-weight: ${(props) => props.$active ? 700 : 400};
  outline: 0;
  
  &:first-child {
    border-left: 0;
  }

  &:not([disabled]):hover {
    background: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.white};
  }
  
  &:before {
    padding-right: 4px;
    content: '${(props) => ArrowsMapBefore[props.$type]}'
  }

  &:after {
    padding-left: 4px;
    content: '${(props) => ArrowsMapAfter[props.$type]}'
  }
  
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
