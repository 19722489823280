import React, { useCallback, useMemo } from 'react';
import paginate from 'jw-paginate';
import _map from 'lodash/map';
import _noop from 'lodash/noop';

import * as S from './styles';

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageClick,
}) => {
  const structure = paginate(totalItems, currentPage, itemsPerPage, 5);

  const onClick = useCallback((i) => {
    onPageClick(i);
  }, [onPageClick])

  const PagesComponent = useMemo(() => {
    return _map(structure.pages, (page) => (
      <S.PageItem
        $active={page === structure.currentPage}
        key={page}
        onClick={() => onClick(page)}
      >
        {page}
      </S.PageItem>
    ))
  }, [structure, onClick]);

  if (structure.totalPages === 1) return null;

  return (
    <S.Pagination>
      <S.PageItem
        onClick={()=> onClick(1)}
        disabled={structure.currentPage === 1}
        $type='first'
        key='first'
      >
        First
      </S.PageItem>
      <S.PageItem
        onClick={()=> onClick(structure.currentPage - 1)}
        disabled={structure.currentPage === 1}
        $type='prev'
        key='prev'
      >
        Prev
      </S.PageItem>

      { PagesComponent }

      <S.PageItem
        onClick={()=> onClick(structure.currentPage + 1)}
        disabled={structure.currentPage === structure.totalPages}
        $type='next'
        key='next'
      >
        Next
      </S.PageItem>
      <S.PageItem
        onClick={()=> onClick(structure.totalPages)}
        disabled={structure.currentPage === structure.totalPages}
        $type='last'
        key='last'
      >
        Last
      </S.PageItem>
    </S.Pagination>
  )
};

Pagination.defaultProps = {
  onPageClick: _noop,
}

export default Pagination;
