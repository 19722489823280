import React, { useMemo, useState } from 'react';
import { Spring } from 'react-spring/renderprops';
import { animated } from 'react-spring';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Visible, useScreenClass } from 'react-grid-system';
import _map from 'lodash/map';
import Button from 'ui/elements/Button';

import Link from 'widgets/Link';
import { slugify } from 'utils/helpers';

import { Topics } from '../utils';
import * as S from './styles';

const Sidebar = () => {
  const { t } = useTranslation('news');
  const screenClass = useScreenClass();
  const [expanded, setExpanded] = useState(() => {
    return ['xs', 'sm'].indexOf(screenClass) === -1;
  });
  const TopicsList = useMemo(() => {
    return [
      <S.Topic key='all'>
        <Button as={Link} to='/news' variant='clean'>
          {t('allCategories')}
        </Button>
      </S.Topic>,
      ..._map(Topics, (topic, i) => (
        <S.Topic key={i}>
          <Button as={Link} to={`/news/${slugify(topic.value)}`} variant='clean'>
            {topic.title}
          </Button>
        </S.Topic>
      ))
    ]
  }, [t]);

  return (
    <S.Topics>
      <Visible xs sm>
        <S.Toggler onClick={() => setExpanded(!expanded)}>
          {t('showCategories')}
        </S.Toggler>
      </Visible>

      {
        ['xs', 'sm'].indexOf(screenClass) !== -1 ? (
          <Spring from={{ height: 0 }} to={{ height: expanded ?  'auto' : 0 }}>
            {({ height }) => (
              <animated.div style={{ height, overflow: 'hidden' }}>
                {
                  TopicsList
                }
              </animated.div>
            )}
          </Spring>
        ) : TopicsList
      }
    </S.Topics>
  )
};

export default Sidebar;
