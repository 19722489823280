import React from 'react';
import { graphql } from 'gatsby';
import Heading from 'ui/elements/Heading';
import Pagination from 'ui/elements/Pagination';

import _get from 'lodash/get';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import withLocalization from 'decorators/withLocalization';
import { flattenResponse } from 'utils/helpers';
import { useNavigation } from 'widgets/Link';

import NewsList from 'modules/News/NewsList'
import Sidebar from 'modules/News/Sidebar'

import { Section } from 'components/Layout/styles';
import PageLayout from 'components/Layout/PageLayout';

const NewsIndex = ({
  data: rawData,
  location,
  pageContext,
}) => {
  const data = flattenResponse(rawData, 'allSanityNews');
  const { i18n } = useTranslation();
  const navigate = useNavigation();
  const hasPagination = _get(pageContext, 'itemsPerPage');

  return (
    <PageLayout sidebar={Sidebar}>
      <GatsbySeo
        title='News'
        openGraph={{
          title: 'News',
          url: location.href,
          locale: i18n.language,
        }}
      />
      <Section>
        <Heading level={1}>News</Heading>
        <NewsList allNews={data} />
        {
          hasPagination && (
            <Pagination
              itemsPerPage={pageContext.itemsPerPage}
              totalItems={pageContext.totalItems}
              currentPage={pageContext.currentPage}
              onPageClick={(i) => {
                const path = i === 1 ? '/news' : `/news/${i}`;
                navigate(path);
              }}
            />
          )
        }
      </Section>
    </PageLayout>
  );
};

export const query = graphql`
  query ReadAllNews($topic: String, $limit: Int, $skip: Int, $language: String!) {
    allSanityNews(
      sort: { fields: date, order: DESC },
      limit: $limit,
      skip: $skip,
      filter: { topics: { eq: $topic } }
    ) {
      edges {
        node {
          id
          slug(lang: $language)
          title {
            localized
          }
          _rawContent(resolveReferences: { maxDepth: 10 })
        }
      }
    }

    locales: allLocale(filter: { language: {eq: $language} }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withLocalization(NewsIndex);
