import React from 'react';
import styled from 'styled-components';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';

import ContentBox from 'widgets/ContentBox';
import Link from 'widgets/Link'

const SLink = styled(Link)`
  color: inherit;
`;

const NewsSummary = ({
  news,
}) => {
  return (
    <article>
      <OpticalHeading level={3} renderAs="h2" color='primary'>
        <SLink to={`/news/${news.slug}`}>
          {news.title.localized}
        </SLink>
      </OpticalHeading>
      <ContentBox blocks={news._rawContent} truncateLines={5} />
    </article>
  )
};

export default NewsSummary;
