import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import NewsSummary from '../NewsSummary';
import * as S from './styles';
import Heading from 'ui/elements/Heading';

const NewsList = ({
  allNews,
}) => {
  const { t } = useTranslation('news');
  if (!allNews || allNews.length === 0) {

    return (
      <S.NoNewsList>
        <Heading level={2}>{t('noNewsAvailable')}</Heading>
      </S.NoNewsList>
    );
  }

  return (
    <S.NewsList>
      {
        allNews.map((news, i) => (
          <NewsSummary key={i} news={news} />
        ))
      }
    </S.NewsList>
  )
};

export default NewsList;
