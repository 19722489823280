import { breakpoint } from 'ui/elements/ThemeProvider/utils';
import styled from 'styled-components';

export const Topics = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  
  ${breakpoint.md`
    text-align: left;
  `}
`;

export const Topic = styled.li`
  color: ${(props) => props.theme.palette.primary};
  cursor: pointer;

  [aria-current="page"] {
    text-decoration: underline;
  }
`;

export const Toggler = styled.span`
  font-weight: 700;
  background: ${(props) => props.theme.palette.primary};
  color: ${(props) => props.theme.palette.white};
  display: block;
  font-size: 1.3rem;
  line-height: 4.5rem;
`;
