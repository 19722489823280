import styled from 'styled-components';


export const NewsList = styled.div`
  > * {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${(props) => props.theme.palette.primary};
  }
`;


export const NoNewsList = styled.div`
  text-align: center;
`;
